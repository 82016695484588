import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";

const Home = () => {
  const [city, setCity] = useState("");
  const [prediction, setPrediction] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // New state variable

  const weatherApiKey = process.env.REACT_APP_WEATHER_API_KEY;
  const openAiApiKey = process.env.REACT_APP_OPENAI_API_KEY;
  const visualCrossingApiKey = process.env.REACT_APP_VISUAL_CROSSING_API_KEY;

  // Existing functions: getNextGroundhogDay, handleInputChange, fetchHistoricalData, fetchForecastData

  // Function to get the next Groundhog Day
  const getNextGroundhogDay = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    let groundhogYear = currentYear;

    if (
      today.getMonth() > 1 || // Months are zero-indexed
      (today.getMonth() === 1 && today.getDate() > 2)
    ) {
      groundhogYear += 1;
    }

    return new Date(groundhogYear, 1, 2); // February 2
  };

  const nextGroundhogDay = getNextGroundhogDay();
  const today = new Date();
  const diffTime = nextGroundhogDay - today;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const handleInputChange = (e) => {
    setCity(e.target.value);
  };

  // Fetch historical data
  const fetchHistoricalData = async (lat, lon, nextGroundhogDay, visualCrossingApiKey) => {
    const years = 5; // Number of years to look back
    const historicalTemps = [];

    for (let i = 1; i <= years; i++) {
      const year = nextGroundhogDay.getFullYear() - i;
      const startDate = `${year}-01-15`;
      const endDate = `${year}-02-15`;

      const historicalResponse = await fetch(
        `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${lat},${lon}/${startDate}/${endDate}?unitGroup=metric&key=${visualCrossingApiKey}&include=days`
      );

      if (!historicalResponse.ok) {
        throw new Error("Failed to fetch historical weather data.");
      }

      const historicalData = await historicalResponse.json();

      // Extract daily high temperatures
      const temps = historicalData.days.map((day) => day.tempmax);
      historicalTemps.push(...temps);
    }

    return historicalTemps;
  };

  // Fetch forecast data
  const fetchForecastData = async (lat, lon, nextGroundhogDay, visualCrossingApiKey) => {
    const forecastDate = nextGroundhogDay.toISOString().split("T")[0]; // Format YYYY-MM-DD

    const forecastResponse = await fetch(
      `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${lat},${lon}/${forecastDate}/${forecastDate}?unitGroup=metric&key=${visualCrossingApiKey}&include=days`
    );

    if (!forecastResponse.ok) {
      throw new Error("Failed to fetch forecast weather data.");
    }

    const forecastData = await forecastResponse.json();
    const forecastTemp = forecastData.days[0]?.tempmax; // Max temp on Groundhog Day

    return forecastTemp;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setPrediction(null);
    setIsLoading(true); // Start loading

    try {
      if (!city.trim()) {
        throw new Error("Please enter a valid city name.");
      }

      const parts = city.split(",").map((part) => part.trim());
      const cityInput = parts[0];
      const state = parts[1] || "";
      const country = parts[2] || "US";
      const query = state ? `${cityInput},${state},${country}` : `${cityInput},${country}`;

      // Fetch geolocation data
      const geoResponse = await fetch(
        `https://api.openweathermap.org/geo/1.0/direct?q=${query}&limit=1&appid=${weatherApiKey}`
      );

      if (!geoResponse.ok) {
        throw new Error("Failed to fetch location data.");
      }

      const geoData = await geoResponse.json();
      if (!geoData || geoData.length === 0) {
        throw new Error("No location data found for the specified city.");
      }

      const { lat, lon } = geoData[0];

      // Fetch historical data
      const historicalTemps = await fetchHistoricalData(
        lat,
        lon,
        nextGroundhogDay,
        visualCrossingApiKey
      );

      if (historicalTemps.length === 0) {
        throw new Error("No historical temperature data available.");
      }

      // Calculate historical average temperature
      const avgHistoricalTemp =
        historicalTemps.reduce((a, b) => a + b, 0) / historicalTemps.length;

      // Fetch forecasted temperature
      const forecastTemp = await fetchForecastData(
        lat,
        lon,
        nextGroundhogDay,
        visualCrossingApiKey
      );

      if (forecastTemp == null) {
        throw new Error("No forecast temperature data available.");
      }

      // Determine if early spring is predicted
      const earlySpring = forecastTemp > avgHistoricalTemp;

      // Update the prompt
      const prompt = earlySpring
      ? `There are ${diffDays} days until Groundhog Day on ${nextGroundhogDay.toDateString()}. Based on the forecasted warmer-than-usual temperatures in ${city}, generate a playful message predicting an early spring for ${nextGroundhogDay.getFullYear()}. Remind the user to come back closer to February 2 for the most accurate prediction. Keep the response under 150 tokens.`
      : `There are ${diffDays} days until Groundhog Day on ${nextGroundhogDay.toDateString()}. Based on the forecasted colder-than-usual temperatures in ${city}, generate a playful message predicting a long winter for ${nextGroundhogDay.getFullYear()}. Remind the user to come back closer to February 2 for the most accurate prediction. Keep the response under 150 tokens.`;

      // Call OpenAI API
      const openAIResponse = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${openAiApiKey}`,
        },
        body: JSON.stringify({
          model: "gpt-4-turbo",
          messages: [
            {
              role: "system",
              content:
                "You are Byte Burrower, the world's first AI groundhog. Provide playful and engaging predictions based on weather data. Keep responses concise and under 150 tokens while maintaining a friendly and engaging tone.",
            },
            { role: "user", content: prompt },
          ],
          max_tokens: 150,
          temperature: 0.7,
        }),
      });

      if (!openAIResponse.ok) {
        throw new Error("Failed to fetch prediction from OpenAI.");
      }

      const aiData = await openAIResponse.json();
      setPrediction(aiData.choices[0].message.content.trim());
    } catch (error) {
      setError(error.message || "An unexpected error occurred.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div>
      <h1>Hi, I'm Byte Burrower</h1>
      <h2>Your digital groundhog powered by AI and weather data, here to predict whether spring will come early or winter will linger. Enter your city for a fun, personalized forecast tailored just for you!</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={city}
          onChange={handleInputChange}
          placeholder="Enter city or town"
        />
        <button type="submit">Get Prediction</button>
      </form>
      {isLoading && (
        <div className="loading" aria-live="polite">
          {/* Spinner and message */}
          {/* If using a CSS spinner */}
          <div className="spinner"></div>
          {/* If using a library spinner */}
          <TailSpin color="#1e90ff" height={50} width={50} />
          <p>Fetching your prediction...</p>
        </div>
      )}
      {error && <div className="error">{error}</div>}
      {prediction && (
        <div className="prediction">
          <strong>Prediction for {city}:</strong>
          <p>
            There are {diffDays} days until Groundhog Day (
            {nextGroundhogDay.toDateString()}).
          </p>
          <p>{prediction}</p>
        </div>
      )}
    </div>
  );
};


export default Home;
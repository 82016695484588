import React from "react"; // Remove { useState } if unused
import "./App.css";
import logo from "./logo.png";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Home"; // Will create this component next
import Navbar from "./components/Navbar";

function App() {
  return (
    <Router>
      <Navbar />
      <div className="content-container">
        <img src={logo} alt="Byte Burrower Logo" />
        {/* Routes handle switching between Home and About */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
import React from "react";

const About = () => {
  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "auto", textAlign: "left" }}>
      <h1 style={{ textAlign: "center" }}>About Byte Burrower</h1>

      {/* Summary Paragraph */}
      <p>
        Byte Burrower is the world’s first AI groundhog, bringing the tradition of Groundhog Day predictions into the digital age with data-driven insights. By analyzing both weather forecasts and historical climate data specific to your location, Byte Burrower offers playful and personalized predictions about whether spring will arrive early or if winter will linger. Simply enter your city, and let Byte Burrower combine cutting-edge AI with meteorological trends to provide a fun and accurate seasonal forecast tailored just for you.
      </p>

      {/* FAQ Section */}
      <h2 style={{ marginTop: "40px" }}>Frequently Asked Questions</h2>
      <div style={{ marginTop: "20px" }}>
        {/* Question 1 */}
        <h3>How does Byte Burrower work?</h3>
        <p>
          Unlike traditional groundhogs that rely on seeing their shadows, Byte Burrower uses a sophisticated blend of data analysis and artificial intelligence to predict seasonal changes. By harnessing real-time weather forecasts and historical climate data through APIs like OpenWeatherMap and Visual Crossing, Byte Burrower analyzes temperature trends specific to your location. It compares forecasted temperatures for the upcoming Groundhog Day with historical averages to determine whether spring will arrive early or if winter will linger. Additionally, the app employs OpenAI’s GPT-4 language model to generate playful and personalized predictions. This modern, data-driven approach ensures Byte Burrower’s forecasts are based on actual meteorological trends rather than folklore, offering a more accurate and engaging prediction than shadows alone ever could.
        </p>

        {/* Question 2 */}
        <h3>Who created Byte Burrower?</h3>
        <p>
          That's a tough question. On September 13, 2024, <a href='https://globalhealth.duke.edu/people/green-eric'>Eric Green</a>, who teaches a class on data science at Duke University, asked ChatGPT to create the first AI groundhog while recording an episode for the <a href='https://countdowntogroundhogday.com/podcast'>"Countdown to Groundhog Day" podcast</a>. The AI groundhog named itself Byte Burrower. Later, Eric returned to ChatGPT to help develop this web app. So who can say, really?
        </p>

        {/* Question 3 */}
        <h3>What's the data source for the non-AI prognosticators?</h3>
        <p>
          None other than <a href='https://countdowntogroundhogday.com/'>Countdown to Groundhog Day</a>!
        </p>

        {/* Question 4 */}
        <h3>How can I contact you?</h3>
        <p>
          Have questions? Feel free to reach out to me at
          <a href="mailto:epgreen+byteburrower@gmail.com"> epgreen+byteburrower@gmail.com</a>.
        </p>
      </div>
    </div>
  );
};

export default About;